<template>
    <div
        id="ChoosePlacementAccountPortfolio"
        :class="s.container"
    >
        <span :class="[theme.quickbalances.subheaderText, s.subheader]">
            Placement
        </span>
        <div :class="s.column">
            <div
                v-for="(placement, index) in isDeFi ? defiPlacementsList : allowedPlacementsList"
                :key="placement.id"
                :class="[
                    s.pointer,
                    {
                        [theme.quickbalances.activeBackground]: isWorkspace
                            ? activeWorkspacePlacementIndex === index
                            : currentPlacementIndex === index,
                        [s.notAllowed]: !getIsPlacementAvailable(placement.name),
                    }
                ]"
                @click="setActivePlacement(index)"
            >
                <div :class="s.row">
                    <PlacementIcon
                        :placement="placement.name"
                        :size="14"
                        :class="{ [s.disabled]: !getIsPlacementAvailable(placement.name) }"
                    />
                    <span
                        :class="[
                            theme.quickbalances.placementText,
                            {
                                [theme.quickbalances.active]: isWorkspace ? activeWorkspacePlacementIndex === index : currentPlacementIndex === index,
                                [s.disabled]: !getIsPlacementAvailable(placement.name)
                            }
                        ]"
                    >
                        {{ placement.name.trim() }}
                    </span>
                    <div
                        v-if="getIsUnified(placement.tag)"
                        :class="[s.unifiedWrapper, { [s.active]: isWorkspace ? activeWorkspacePlacementIndex === index : currentPlacementIndex === index }]"
                    >
                        UNIFIED
                    </div>
                    <Icon
                        :class="[s.violet, { [s.disabled]: !getIsPlacementAvailable(placement.name) }]"
                        icon="check"
                        v-if="isWorkspace ? activeWorkspacePlacementIndex === index : currentPlacementIndex === index"
                        small
                    />
                    <Icon
                        v-if="!getIsPlacementAvailable(placement.name)"
                        icon="clock"
                        :class="s.clock"
                    />
                    <Icon
                        v-else-if="!getIsPlacementLinked(placement.name) && activeAccountId !== 'DEMOACC'"
                        icon="link"
                        :class="s.link"
                        @clicked="linkPlacement(placement.name)"
                    />
                </div>
                <div :class="s.marginBalanceRow">
                    <span :class="[s.totalRow, { [s.disabled]: !getIsPlacementAvailable(placement.name) }]">
                        ≈{{ quotationAssetCharacter }}{{ (getNeedMarginData(placement.name) ? getEquity(placement.name) : totalPlacementBalance(placement.name)).toFixed(quotationAssetPrecision) }}
                    </span>
                    <div v-if="getNeedMarginData(placement.name)" :class="s.separator"></div>
                    <span
                        v-if="getNeedMarginData(placement.name)"
                        :class="[s.totalRow, s.black, { [s.disabled]: !getIsPlacementAvailable(placement.name) }]"
                    >
                        ≈{{ quotationAssetCharacter }}{{ getQuantity(placement.name).toFixed(quotationAssetPrecision) }}
                    </span>
                    <Info
                        :ref="`Info${placement.name}`"
                        v-if="getNeedMarginData(placement.name)"
                        :text="infoTooltipText"
                        is-position-fixed
                        is-question
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { RELEASED_PLATFORMS } from 'Store/modules/Placements';
import { setAccountPortfolioActivePlacementId } from 'Store/v2/Balances';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import { SET_PLACEMENT_NAME } from 'Store/v2/LinkPlacement';
import Info from 'UI/Info.vue';
import { UnifiedExchangesGroups, UnifiedExchangesTags } from 'Store/v2/Transfer';

export default {
    name: 'AccountPortfolio.ChoosePlacement',
    props: {
        isDeFi: {
            type: Boolean,
            default: false,
        },
        isWorkspace: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Info,
        Icon,
    },
    data() {
        return {
            activeWorkspacePlacementIndex: 1,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            placementsList: 'Placements/placements',
            accountPortfolioActivePlacementId: 'Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccountId: 'Accounts/activeAccountID',
        }),
        totalBalances() {
            return this.$store.state.Margin.totalBalances;
        },
        defiPlacementsList() {
            return this.placementsList.filter((p) => p.name === 'Single Broker');
        },
        filteredPlacementsList() {
            return this.placementsList.filter((p) => p.type.indexOf('decentralized') === -1);
        },
        allowedPlacementsList() {
            return this.filteredPlacementsList
                // eslint-disable-next-line no-prototype-builtins
                .filter((p) => RELEASED_PLATFORMS.hasOwnProperty(p.name) || p.name === 'Single Broker')
                .filter(({ tag, type }) => !UnifiedExchangesTags.has(tag) || type === 'crypto-spot');
        },
        finalPlacementsList() {
            return this.isDeFi ? this.defiPlacementsList : this.allowedPlacementsList;
        },
        currentPlacementIndex() {
            return this.isDeFi
                ? 0
                : this.allowedPlacementsList.findIndex((placement) => Number(placement.id) === Number(this.accountPortfolioActivePlacementId));
        },
        balances() {
            return this.$store.state.Balances.balances;
        },
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        infoTooltipText() {
            return '<span style="font-size: 12px; line-height: 105%;"><strong>Equity Balance</strong> - the total value of assets in your account, including Wallet Balance and Unrealized P&L from open positions.\n\n<strong>Margin Balance</strong> - the total amount of margin available in your account, combining Wallet Balance and Unrealized P&L. Used to determine if positions can be maintained or new ones can be opened.\n<span style="font-size: 10px; color: var(--cl-gray)">Margin Balance = Wallet Balance + Unrealized P&L</span></span>';
        },
    },
    methods: {
        validateActiveAccountPortfolioActivePlacementId(id) {
            const placement = this.$store.state.Placements.placements.find(({ id: placementId }) => placementId === id);
            if (UnifiedExchangesTags.has(placement.tag) && placement.type !== 'crypto-spot') {
                const groupIndex = UnifiedExchangesGroups.findIndex((group) => group.some((groupMember) => groupMember === placement.tag));
                const spotTag = UnifiedExchangesGroups[groupIndex].find((tag) => tag !== placement.tag);
                const trueIndex = this.allowedPlacementsList.findIndex(({ tag }) => tag === spotTag);
                this.setActivePlacement(trueIndex !== -1 ? trueIndex : 0);
            }
        },
        getIsUnified(tag) {
            return UnifiedExchangesTags.has(tag);
        },
        closeAllInfoTooltips() {
            this.finalPlacementsList.forEach((placement) => {
                if (this.getNeedMarginData(placement.name)) {
                    this.$refs[`Info${placement.name}`][0].closeTooltip();
                }
            });
        },
        setCloseInfoTooltipsListeners() {
            if (
                this.finalPlacementsList
                && this.finalPlacementsList.length > 0
                && this.totalBalances.length > 0
            ) {
                document.querySelector('body').addEventListener('scroll', this.closeAllInfoTooltips);
                document.querySelector('#ChoosePlacementAccountPortfolio').addEventListener('scroll', this.closeAllInfoTooltips);
            }
        },
        getNeedMarginData(placementName) {
            const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags
                .get(placementName.toUpperCase());
            if (!currentPlacementTag) {
                return false;
            }

            return this.$store.state.Margin.totalBalances.some(({ placementTags }) => (
                placementTags.some((tag) => tag === currentPlacementTag)
            ));
        },
        getEquity(placementName) {
            if (!this.getNeedMarginData(placementName)) {
                return 0;
            }

            const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags
                .get(placementName.toUpperCase());
            return Number(this.$store.state.Margin.totalBalances.find(({ placementTags }) => (
                placementTags.some((tag) => tag === currentPlacementTag)
            ))?.equity || 0);
        },
        getQuantity(placementName) {
            if (!this.getNeedMarginData(placementName)) {
                return 0;
            }

            const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags
                .get(placementName.toUpperCase());
            return Number(this.$store.state.Margin.totalBalances.find(({ placementTags }) => (
                placementTags.some((tag) => tag === currentPlacementTag)
            ))?.quantity || 0);
        },
        async setActivePlacement(index) {
            const placementStatuses = this.$store.state.Placements.maintenanceStatuses;

            if (this.isWorkspace) {
                if (placementStatuses.get(this.allowedPlacementsList[index].name)
                    || this.allowedPlacementsList[index].name === 'Single Broker') {
                    this.activeWorkspacePlacementIndex = index;
                    if (this.allowedPlacementsList[this.activeWorkspacePlacementIndex]) {
                        this.$emit('set-placement', this.allowedPlacementsList[this.activeWorkspacePlacementIndex].id);
                    }
                }
            } else {
                if (this.isDeFi) {
                    if (!placementStatuses.get(this.defiPlacementsList[index].name)
                        && this.defiPlacementsList[index].name !== 'Single Broker') {
                        return;
                    }
                } else if (!placementStatuses.get(this.allowedPlacementsList[index].name)
                    && this.allowedPlacementsList[index].name !== 'Single Broker') {
                    return;
                }
                await this.$store.dispatch(setAccountPortfolioActivePlacementId(this.isDeFi ? this.defiPlacementsList[index].id : this.allowedPlacementsList[index].id));
            }
        },
        totalPlacementBalance(name) {
            return this.balances.reduce((accum, { accountId, placementName, quotationAssetSymbols, quotations, total }) => {
                if (placementName !== name || accountId !== this.activeAccountId) {
                    return accum;
                }
                const quotationAssetSymbolIndex = quotationAssetSymbols.findIndex((symbol) => symbol === this.quotationAssetSymbol);
                if (quotationAssetSymbolIndex === -1) {
                    return accum;
                }
                return accum + (Number(quotations[quotationAssetSymbolIndex]) * total);
            }, 0);
        },
        getIsPlacementDisabled(placementName) {
            return (!this.$store.state.Placements.maintenanceStatuses.get(placementName) && placementName !== 'Single Broker')
                || (!this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](placementName) && placementName !== 'Single Broker');
        },
        getIsPlacementAvailable(placementName) {
            return this.$store.state.Placements.maintenanceStatuses.get(placementName) || placementName === 'Single Broker';
        },
        getIsPlacementLinked(placementName) {
            return this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](placementName) || placementName === 'Single Broker';
        },
        async linkPlacement(placementName) {
            const placement = this.$store.state.Placements.placements.find((p) => p.name.toLowerCase() === placementName.toLowerCase());
            if (placement && placement.needExternalKyc) {
                this.$store.commit(SET_PLACEMENT_NAME(placementName));
                this.$modal.show('confirmLinkModal');
            } else {
                await this.$store.dispatch('Accounts/linkPlacement', placementName);
            }
        },
    },
    async mounted() {
        if (this.isDeFi) {
            await this.setActivePlacement(0);
            this.$emit('set-placement', this.defiPlacementsList[0].id);
            return;
        }
        if (this.isWorkspace && this.allowedPlacementsList[this.activeWorkspacePlacementIndex]) {
            this.$emit('set-placement', this.allowedPlacementsList[this.activeWorkspacePlacementIndex].id);
        }
        this.setCloseInfoTooltipsListeners();
    },
    beforeDestroy() {
        document.querySelector('body').removeEventListener('scroll', this.closeAllInfoTooltips);
        document.querySelector('#ChoosePlacementAccountPortfolio').removeEventListener('scroll', this.closeAllInfoTooltips);
    },
    watch: {
        async defiPlacementsList() {
            if (this.isDeFi) {
                await this.setActivePlacement(0);
            }
        },
        finalPlacementsList() {
            this.setCloseInfoTooltipsListeners();
        },
        totalBalances() {
            this.setCloseInfoTooltipsListeners();
        },
        accountPortfolioActivePlacementId(id) {
            this.validateActiveAccountPortfolioActivePlacementId(id);
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    margin-right: var(--m-s);
    padding: var(--m-s) 0 var(--m-m) var(--m-s);
    max-height: 100%;
    overflow-y: scroll;
}
.pointer {
    cursor: pointer;
}
.subheader {
    padding-left: var(--m-s);
    margin-bottom: var(--m-s);
}
.column {
    display: flex;
    flex-direction: column;
    row-gap: var(--m-s);
}
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
    padding: var(--m-s) var(--m-s) var(--m-xs) var(--m-s);
}
.violet {
    color: var(--cl-violet);
}
.marginBalanceRow {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
    padding: 0 var(--m-s) var(--m-s) calc(14px + var(--m-xs) + var(--m-s));
    position: relative;
    & .separator {
        min-width: 1px;
        height: 12px;
        background-color: var(--cl-gray);
    }
}
.totalRow {
    display: flex;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    color: var(--cl-gray);
    &.black {
        color: var(--cl-black);
    }
}
.notAllowed {
    cursor: not-allowed;
}
.disabled {
    opacity: .4;
}
.clock {
    color: var(--cl-gray);
}
.link {
    color: var(--cl-violet);
    cursor: pointer;
}
.unifiedWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background-color: var(--cl-gray);
    border-radius: 100px;
    padding: var(--m-ss) var(--m-s);
    font-size: var(--fs-xxs);
    font-weight: var(--fw-bold);
    color: var(--cl-white);
    text-align: center;
    line-height: normal;

    &.active {
        background-color: var(--cl-violet);
        color: #fff;
    }
}
</style>
