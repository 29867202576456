var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.s.container,attrs:{"id":"ChoosePlacementAccountPortfolio"}},[_c('span',{class:[_vm.theme.quickbalances.subheaderText, _vm.s.subheader]},[_vm._v(" Placement ")]),_c('div',{class:_vm.s.column},_vm._l((_vm.isDeFi ? _vm.defiPlacementsList : _vm.allowedPlacementsList),function(placement,index){return _c('div',{key:placement.id,class:[
                _vm.s.pointer,
                {
                    [_vm.theme.quickbalances.activeBackground]: _vm.isWorkspace
                        ? _vm.activeWorkspacePlacementIndex === index
                        : _vm.currentPlacementIndex === index,
                    [_vm.s.notAllowed]: !_vm.getIsPlacementAvailable(placement.name),
                }
            ],on:{"click":function($event){return _vm.setActivePlacement(index)}}},[_c('div',{class:_vm.s.row},[_c('PlacementIcon',{class:{ [_vm.s.disabled]: !_vm.getIsPlacementAvailable(placement.name) },attrs:{"placement":placement.name,"size":14}}),_c('span',{class:[
                        _vm.theme.quickbalances.placementText,
                        {
                            [_vm.theme.quickbalances.active]: _vm.isWorkspace ? _vm.activeWorkspacePlacementIndex === index : _vm.currentPlacementIndex === index,
                            [_vm.s.disabled]: !_vm.getIsPlacementAvailable(placement.name)
                        }
                    ]},[_vm._v(" "+_vm._s(placement.name.trim())+" ")]),(_vm.getIsUnified(placement.tag))?_c('div',{class:[_vm.s.unifiedWrapper, { [_vm.s.active]: _vm.isWorkspace ? _vm.activeWorkspacePlacementIndex === index : _vm.currentPlacementIndex === index }]},[_vm._v(" UNIFIED ")]):_vm._e(),(_vm.isWorkspace ? _vm.activeWorkspacePlacementIndex === index : _vm.currentPlacementIndex === index)?_c('Icon',{class:[_vm.s.violet, { [_vm.s.disabled]: !_vm.getIsPlacementAvailable(placement.name) }],attrs:{"icon":"check","small":""}}):_vm._e(),(!_vm.getIsPlacementAvailable(placement.name))?_c('Icon',{class:_vm.s.clock,attrs:{"icon":"clock"}}):(!_vm.getIsPlacementLinked(placement.name) && _vm.activeAccountId !== 'DEMOACC')?_c('Icon',{class:_vm.s.link,attrs:{"icon":"link"},on:{"clicked":function($event){return _vm.linkPlacement(placement.name)}}}):_vm._e()],1),_c('div',{class:_vm.s.marginBalanceRow},[_c('span',{class:[_vm.s.totalRow, { [_vm.s.disabled]: !_vm.getIsPlacementAvailable(placement.name) }]},[_vm._v(" ≈"+_vm._s(_vm.quotationAssetCharacter)+_vm._s((_vm.getNeedMarginData(placement.name) ? _vm.getEquity(placement.name) : _vm.totalPlacementBalance(placement.name)).toFixed(_vm.quotationAssetPrecision))+" ")]),(_vm.getNeedMarginData(placement.name))?_c('div',{class:_vm.s.separator}):_vm._e(),(_vm.getNeedMarginData(placement.name))?_c('span',{class:[_vm.s.totalRow, _vm.s.black, { [_vm.s.disabled]: !_vm.getIsPlacementAvailable(placement.name) }]},[_vm._v(" ≈"+_vm._s(_vm.quotationAssetCharacter)+_vm._s(_vm.getQuantity(placement.name).toFixed(_vm.quotationAssetPrecision))+" ")]):_vm._e(),(_vm.getNeedMarginData(placement.name))?_c('Info',{ref:`Info${placement.name}`,refInFor:true,attrs:{"text":_vm.infoTooltipText,"is-position-fixed":"","is-question":""}}):_vm._e()],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }